[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-paytable:before {
  content: "\e991";
}
.icon-minus:before {
  content: "\e990";
}
.icon-loop:before {
  content: "\e98f";
}
.icon-bonuses:before {
  content: "\e98e";
}
.icon-turn-on:before {
  content: "\e919";
}
.icon-marketplace-complete:before {
  content: "\e96d";
}
.icon-marketplace-play:before {
  content: "\e98c";
}
.icon-trash-bin:before {
  content: "\e98b";
}
.icon-email:before {
  content: "\e98a";
}
.icon-lottery-ticket-history:before {
  content: "\e989";
}
.icon-lottery-ticket:before {
  content: "\e987";
}
.icon-cursor:before {
  content: "\e988";
}
.icon-csgofast:before {
  content: "\e986";
}
.icon-plus:before {
  content: "\e985";
}
.icon-sort-overprice:before {
  content: "\e97b";
}
.icon-sort-underprice:before {
  content: "\e97c";
}
.icon-desc-sort-name:before {
  content: "\e980";
}
.icon-desc-sort-price:before {
  content: "\e981";
}
.icon-asc-sort-name:before {
  content: "\e983";
}
.icon-asc-sort-price:before {
  content: "\e984";
}
.icon-fast-delivery:before {
  content: "\e979";
}
.icon-redirect-link:before {
  content: "\e978";
}
.icon-marketplace-delete:before {
  content: "\e976";
}
.icon-marketplace-pause:before {
  content: "\e977";
}
.icon-search:before {
  content: "\e974";
}
.icon-refresh:before {
  content: "\e975";
}
.icon-warn:before {
  content: "\e972";
}
.icon-lock-temporarily:before {
  content: "\e973";
}
.icon-hourglass-outline:before {
  content: "\e96b";
}
.icon-hourglass:before {
  content: "\e970";
}
.icon-decagram:before {
  content: "\e971";
}
.icon-bonus-experience:before {
  content: "\e96c";
}
.icon-bonus-case:before {
  content: "\e98d";
}
.icon-bonus-lvl-up:before {
  content: "\e96e";
}
.icon-bonus-wheel:before {
  content: "\e96f";
}
.icon-credit-card:before {
  content: "\e969";
}
.icon-award-player:before {
  content: "\e96a";
}
.icon-alert-error:before {
  content: "\e966";
}
.icon-users-plus:before {
  content: "\e915";
}
.icon-award-symbol:before {
  content: "\e910";
}
.icon-rules:before {
  content: "\e967";
}
.icon-top:before {
  content: "\e968";
}
.icon-content-copy:before {
  content: "\e965";
}
.icon-edit:before {
  content: "\e964";
}
.icon-twitch:before {
  content: "\e962";
}
.icon-telegram:before {
  content: "\e963";
}
.icon-balance:before {
  content: "\e928";
}
.icon-statistics:before {
  content: "\e95c";
}
.icon-history:before {
  content: "\e95c";
}
.icon-levels:before {
  content: "\e95d";
}
.icon-notifications:before {
  content: "\e95f";
}
.icon-referrals:before {
  content: "\e961";
}
.icon-steam-provider:before {
  content: "\e943";
}
.icon-email-check:before {
  content: "\e957";
}
.icon-star-outline:before {
  content: "\e904";
}
.icon-star-fill:before {
  content: "\e942";
}
.icon-lock-fill:before {
  content: "\e941";
}
.icon-checked-fill:before {
  content: "\e940";
}
.icon-poggi-mini:before {
  content: "\e920";
}
.icon-baccarat-mini:before {
  content: "\e930";
}
.icon-cases-mini:before {
  content: "\e931";
}
.icon-classic-mini:before {
  content: "\e932";
}
.icon-craft-mini:before {
  content: "\e933";
}
.icon-crash-mini:before {
  content: "\e934";
}
.icon-double-mini:before {
  content: "\e935";
}
.icon-bets-mini:before {
  content: "\e936";
}
.icon-fast-mini:before {
  content: "\e937";
}
.icon-wheel-mini:before {
  content: "\e938";
}
.icon-hilo-mini:before {
  content: "\e939";
}
.icon-slot-mini:before {
  content: "\e93b";
}
.icon-tower-mini:before {
  content: "\e93c";
}
.icon-x50-mini:before {
  content: "\e93d";
}
.icon-arrow-bold:before {
  content: "\e91f";
}
.icon-leaders:before {
  content: "\e909";
}
.icon-marketplace:before {
  content: "\e90b";
}
.icon-log-out:before {
  content: "\e92f";
}
.icon-login:before {
  content: "\e93a";
}
.icon-lobby:before {
  content: "\e93e";
}
.icon-menu:before {
  content: "\e93f";
}
.icon-coupon:before {
  content: "\e92c";
}
.icon-users:before {
  content: "\e92d";
}
.icon-lock:before {
  content: "\e92a";
}
.icon-checked:before {
  content: "\e92b";
}
.icon-level-up:before {
  content: "\e925";
}
.icon-skins:before {
  content: "\e926";
}
.icon-cashback:before {
  content: "\e917";
}
.icon-referral:before {
  content: "\e918";
}
.icon-time-date:before {
  content: "\e91b";
}
.icon-leader-case:before {
  content: "\e913";
}
.icon-shared:before {
  content: "\e921";
}
.icon-pointer:before {
  content: "\e914";
}
.icon-user-plus:before {
  content: "\e911";
}
.icon-stickers:before {
  content: "\e90f";
}
.icon-arrow-up:before {
  content: "\e90e";
}
.icon-append-bubble:before {
  content: "\e90d";
}
.icon-coins:before {
  content: "\e90c";
}
.icon-drawer-arrow:before {
  content: "\e907";
}
.icon-support:before {
  content: "\e905";
}
.icon-faq:before {
  content: "\e902";
}
.icon-assistance:before {
  content: "\e902";
}
.icon-freecoins:before {
  content: "\e903";
}
.icon-leaders1:before {
  content: "\e906";
}
.icon-refill:before {
  content: "\e908";
}
.icon-sait:before {
  content: "\e912";
}
.icon-withdrawal:before {
  content: "\e90a";
}
.icon-arrow:before {
  content: "\e901";
}
.icon-vgo:before {
  content: "\e900";
}
.icon-1912:before {
  content: "\e900";
}
.icon-close:before {
  content: "\e929";
}
.icon-fun:before {
  content: "\e91a";
}
.icon-1:before {
  content: "\e91a";
}
.icon-dota2:before {
  content: "\e91c";
}
.icon-570:before {
  content: "\e91c";
}
.icon-csgo:before {
  content: "\e91d";
}
.icon-730:before {
  content: "\e91d";
}
.icon-gift:before {
  content: "\e91e";
}
.icon-settings:before {
  content: "\e922";
}
.icon-volume-on:before {
  content: "\e923";
}
.icon-volume-off:before {
  content: "\e924";
}
.icon-notifications-check:before {
  content: "\e944";
}
.icon-error:before {
  content: "\e945";
}
.icon-success:before {
  content: "\e946";
}
.icon-minus-circle:before {
  content: "\e947";
  color: #484c52;
}
.icon-plus-circle:before {
  content: "\e948";
  color: #484c52;
}
.icon-poggi:before {
  content: "\e92e";
}
.icon-baccarat:before {
  content: "\e949";
}
.icon-bets:before {
  content: "\e94a";
}
.icon-cases:before {
  content: "\e94b";
}
.icon-classic:before {
  content: "\e94c";
}
.icon-craft:before {
  content: "\e94d";
}
.icon-crash:before {
  content: "\e94e";
}
.icon-double:before {
  content: "\e94f";
}
.icon-fast:before {
  content: "\e950";
}
.icon-hilo:before {
  content: "\e951";
}
.icon-slots:before {
  content: "\e952";
}
.icon-tower:before {
  content: "\e953";
}
.icon-wheel:before {
  content: "\e954";
}
.icon-leader-spin:before {
  content: "\e916";
}
.icon-x50:before {
  content: "\e955";
}
.icon-chat:before {
  content: "\e956";
}
.icon-facebook:before {
  content: "\e959";
}
.icon-twitter:before {
  content: "\e95a";
}
.icon-steam:before {
  content: "\e95b";
}
.icon-vkontakte:before {
  content: "\e958";
}
.icon-google:before {
  content: "\e927";
}
.icon-account:before {
  content: "\e95e";
}
.icon-bonus:before {
  content: "\e960";
}
/* .icon-faucet:before {
  content: "\e97a";
} */
.icon-quest .path1:before {
  content: "\e97d";
  color: rgb(0, 186, 217);
}
.icon-quest .path2:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-quest .path3:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(0, 186, 217);
}
.icon-yandex:before {
  content: "\e982";
}
