/* https://stackoverflow.com/questions/42675725/isolated-styled-components-with-font-face */
@font-face {
  font-family: 'a_LCDNova';
  src: url('./../fonts/a_LCDNova.woff2') format('woff2'),
    url('./../fonts/a_LCDNova.woff') format('woff'),
    url('./../fonts/a_LCDNova.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'icomoon';
  src: url('./../fonts/icomoon.woff2') format('woff2'),
    url('./../fonts/icomoon.woff') format('woff'),
    url('./../fonts/icomoon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'icomoon';
  src: url('./../fonts/icomoon.eot?6jjgc3');
  src: url('./../fonts/icomoon.eot?6jjgc3#iefix') format('embedded-opentype'),
    url('./../fonts/icomoon.ttf?6jjgc3') format('truetype'),
    url('./../fonts/icomoon.woff?6jjgc3') format('woff'),
    url('./../fonts/icomoon.svg?6jjgc3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+E900-E991,U+25Ce;
}

@font-face {
  font-family: 'Burlesk';
  src:  url('./../fonts/Burlesk.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'Roboto';
  src: url('./../fonts/Roboto-Bold.woff2') format('woff2'),
    url('./../fonts/Roboto-Bold.woff') format('woff'),
    url('./../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./../fonts/Roboto-Light.woff2') format('woff2'),
    url('./../fonts/Roboto-Light.woff') format('woff'),
    url('./../fonts/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

/* NOTE: Needed to hide chatra widget and open by our own buttons */
/* #chatra:not(.chatra--expanded) {
  visibility: hidden !important;
  opacity: 0 !important;
  pointer-events: none;
  transition: none;
} */